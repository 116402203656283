import { helpers, between, required, email, numeric } from "@vuelidate/validators";
import { CURRENT_YEAR, formatCurrencyWithScaling } from "@/assets/logic/helpers";
import { useWealthaboutStore } from "@/stores/wealthaboutStore";

// https://vuelidate.js.org/#custom-validators

// Este validator añade un * al label del campo. (cualquier validator que contenga la palabra required o isRequired)
export const requiredWithErrorText = helpers.withMessage("Este campo es obligatorio", required);
export const numericWithErrorText = helpers.withMessage("Este campo es numerico", numeric);
export const betweenWithErrorText = (min, max) => helpers.withMessage(`El valor debe estar dentro del rango [ ${min} - ${max} ]`, between(min, max));
export const betweenPercentageWithErrorText = (min, max) =>
  helpers.withMessage(`El valor debe estar dentro del rango [ ${min}% - ${max}% ]`, between(min, max));
export const betweenWithErrorTextCurrency = (min, max) =>
  helpers.withMessage(
    `El valor debe estar dentro del rango [ ${formatCurrencyWithScaling(min)} - ${formatCurrencyWithScaling(max)} ]`,
    between(min, max)
  );
export const emailWithErrorText = helpers.withMessage("Este campo debe ser un correo electrónico", email);
export const checkIsAYear = helpers.withMessage("La fecha del evento debe ser un año", year => {
  return !isNaN(year);
});
export const checkIsYearAfter = helpers.withMessage("La fecha del evento debe ser mayor al año actual", year => {
  const intYear = year instanceof Date ? year.getFullYear() : parseInt(year);
  return intYear > CURRENT_YEAR;
});
export const checkIsCurrentYearOrLater = helpers.withMessage("La fecha del evento debe ser mayor o igual al año actual", year => {
  const intYear = year instanceof Date ? year.getFullYear() : parseInt(year);
  return intYear >= CURRENT_YEAR;
});
export const checkYearIsFromStart = helpers.withMessage(
  "La fecha inicial del evento debe ser a partir de la fecha de inicio del recurso",
  (year, context) => {
    const intYear = parseInt(year);
    return intYear >= useWealthaboutStore().yearsRange[0];
  }
);
export const pastYear = helpers.withMessage("La fecha debe ser anterior o igual al año actual", year => {
  if (year === "") return true;
  const intYear = parseInt(year);
  return intYear <= CURRENT_YEAR;
});

export const checkIsInNearPast = helpers.withMessage("El año de adquisición no puede ser anterior a 1900", purchaseYear => {
  return parseInt(purchaseYear) > 1900;
});

export const notNegative = helpers.withMessage("El valor no puede ser negativo", between(0.0, Infinity));

export const moreThanZero = helpers.withMessage("El valor debe ser mayor a cero", between(0.01, Infinity));

export const validateCIF = helpers.withMessage("El CIF introducido no tiene un formato válido", cif => {
  if (!cif) return true;
  return /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/.test(cif);
});

export const validateNIF = helpers.withMessage("Formato de NIF inválido", nif => {
  if (nif === "") return true;
  return /^\d{8}[A-Za-z]$/.test(nif);
});

export const validateHoldingToken = helpers.withMessage("El token de acceso introducido no es válido", token => {
  if (!token) return true;
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(token);
});

export const checkIsAfterYear = initYear =>
  helpers.withMessage(`La fecha debe ser mayor o igual a ${initYear}`, year => {
    const intYear = year instanceof Date ? year.getFullYear() : parseInt(year);
    const limitYear = parseInt(initYear);
    return intYear >= limitYear;
  });
export const checkYearIsBeforeEnd = endYear =>
  helpers.withMessage(
    endYear
      ? `La fecha inicial del evento debe ser anterior a la fecha de fin del recurso ${endYear}`
      : "La fecha inicial del evento debe ser anterior a la fecha de fin del recurso",
    year => {
      const intYear = year instanceof Date ? year.getFullYear() : parseInt(year);
      const limitYear = endYear instanceof Date ? endYear.getFullYear() : parseInt(endYear);
      return intYear <= limitYear;
    }
  );
export const notMoreThanEndYear = endYear =>
  helpers.withMessage(`El año no puede ser superior a ${endYear}`, year => {
    const intYear = year instanceof Date ? year.getFullYear() : parseInt(year);
    const limitYear = endYear instanceof Date ? endYear.getFullYear() : parseInt(endYear);
    return intYear <= limitYear;
  });

export const checkIsDate = helpers.withMessage("La fecha introducida no es válida", date => {
  return date instanceof Date && !isNaN(date);
});

export const checkIsDateAfter = initDate =>
  helpers.withMessage(`La fecha debe ser mayor o igual a ${initDate}`, date => {
    return date >= initDate;
  });

export const checkIsDateBefore = endDate =>
  helpers.withMessage(`La fecha debe ser menor o igual a ${endDate}`, date => {
    return date <= endDate;
  });
